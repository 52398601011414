import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import decodeToken from 'jwt-decode';
import { User } from 'src/app/model/user';
import { Location } from '@angular/common';
import { AuthService } from '../auth.service';
import { SharedService } from '../shared.service';
import { CookieService } from 'ngx-cookie';
// import { CookieService } from '../cookie.service';
// import { CookieService } from 'ngx-cookie';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  currentUser = [];
  constructor( 
    private router: Router,
    private location: Location,
    private authService: AuthService,
    private cookieService: CookieService,
    private shared: SharedService
    ) { 
  }
  canActivate(activeRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean { 
      

       let token = this.cookieService.get('_aai') || "";
      //if(this.authService.isBrowserLoad()){
      //  token = localStorage.getItem('_aai') || "";
      //}
      // console.log(token)
      // console.log('auth', this.currentUser) 
      try {
        let url = this.location.path();
        // console.log(url)
        if(token !== ""){
          // console.log('auth -1')
          let payLoad: User = decodeToken(token);
          // console.log(payLoad)
          if(payLoad.userId == undefined){
            // console.log(1)
            // localStorage.removeItem('_aai');
            //this.router.navigate(['/app-auth/login'], { queryParams: {redirect: encodeURIComponent(url)} });
            this.shared.redirectToAuthForUnauthenticatedUser(url);
            return false;
          }
          // console.log('route data', activeRoute.data.roles);
          if (activeRoute.data.roles && activeRoute.data.roles.indexOf(payLoad.role) === -1) {
            // role not authorised so redirect to home page
            this.router.navigate(['/unauthorised']);
            return false;
          }
          let date = new Date().getTime()/1000;
          // console.log(payLoad.exp, '>', date);
          if(payLoad.exp > date){
            return true;
          }
          else{ // refresh token after expiry    
            // localStorage.removeItem('_aai');
            // console.log(3)
            this.shared.redirectToAuthForUnauthenticatedUser(url);
            
            return false;
          }   
        }
        // console.log('auth-6')
        // localStorage.removeItem('_aai');
        // console.log(4)
        this.shared.redirectToAuthForUnauthenticatedUser(url);
        return false;
      } catch (error) {
        console.log(error)
        return false;
      }
  }
}
