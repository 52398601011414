import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-not-found',
  templateUrl: './content-not-found.component.html',
  styleUrls: ['./content-not-found.component.scss']
})
export class ContentNotFoundComponent implements OnInit {
  @Input() contentNotFound: boolean = false;
  @Input() errorMessage: string = "The Content your looking has either wrong or removed from page.";
  constructor() { }

  ngOnInit(): void {
  }

}
