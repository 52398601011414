import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WishListService {

  constructor(
    private _http: HttpClient,
  ) { }

  adWishList(apiName: any) {
    let headersRequest = new HttpHeaders({
      'No-Auth': 'false'
    })
    let url = environment.NODE_APP_API + apiName;
    return this._http.post(url, { headers: headersRequest, observe: 'response' })
  }

  getAllWishList(apiName: any) {
    let headersRequest = new HttpHeaders({
      'No-Auth': 'false'
    })
    let url = environment.NODE_APP_API + apiName;
    return this._http.get(url, { headers: headersRequest, observe: 'response' })
  }

  deleteWishList(apiName: any) {
    let headersRequest = new HttpHeaders({
      'No-Auth': 'false'
    })
    let url = environment.NODE_APP_API + apiName;
    return this._http.delete(url, { headers: headersRequest, observe: 'response' })
  }

}
