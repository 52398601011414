import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MyAccountService {

  constructor(
    private _http: HttpClient
  ) { }
  private adDataSource = new BehaviorSubject<any>(null);
  currentData = this.adDataSource.asObservable();
  getUserDetails(apiName: any) {
    let headersRequest = new HttpHeaders({
      'No-Auth': 'false'
    })
    let url = environment.NODE_APP_API_AUTH + apiName;
    return this._http.get(url, { headers: headersRequest, observe: 'response' })
  }


  changeData(data: any) {
    this.adDataSource.next(data); // Update the data
  };

  userAvailability(apiName: any) {
    let headersRequest = new HttpHeaders({
      'No-Auth': 'false'
    })
    let url = environment.NODE_APP_API_AUTH + apiName;
    return this._http.get(url, { headers: headersRequest, observe: 'response' })
  }

  editUserDetails(apiName: any, data: any) {
    let headersRequest = new HttpHeaders({
      'No-Auth': 'false'
    })
    let url = environment.NODE_APP_API_AUTH + apiName;
    return this._http.put(url, data, { headers: headersRequest, observe: 'response' })
  }

  subscriptionConfirmation(apiName: any) {
    let headersRequest = new HttpHeaders({
      'No-Auth': 'false'
    })
    let url = environment.NODE_APP_API + apiName;
    return this._http.post(url, { headers: headersRequest, observe: 'response' })
  }
}
