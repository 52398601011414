import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NodeApiCallService } from './node-api-call.service';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor(
    private nodeApi: NodeApiCallService
  ) { }
  addToCart(cartData: any): Observable<any>{
    return new Observable<any>(observer => {
      this.nodeApi.commonPostCallWithAuth(cartData, 'app/store/cart').subscribe(result => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      })
    })
  }


  
}
