import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NodeApiCallService } from './node-api-call.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private nodeApi: NodeApiCallService
  ) { }

  addFollow(followData: any): Observable<any>{
    return new Observable<any>(observer => {
      this.nodeApi.commonPostCallWithAuth(followData, 'app/user/follow').subscribe(result => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      })
    })
  }
  
}
