import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ViewServiceService {

  constructor(
    private _http: HttpClient
  ) { };
  getAllAds(apiName: any) {
    let headersRequest = new HttpHeaders({
      'No-Auth': 'false'
    })
    let url = environment.NODE_APP_API + apiName;
    return this._http.get(url, { headers: headersRequest, observe: 'response' })
  }

  addToCart(apiName: any, data: any) {
    let headersRequest = new HttpHeaders({
      'No-Auth': 'false'
    })
    let url = environment.NODE_APP_API + apiName;
    return this._http.post(url, data, { headers: headersRequest, observe: 'response' })
  }

  deleteAd(apiName: any) {
    let headersRequest = new HttpHeaders({
      'No-Auth': 'false'
    })
    let url = environment.NODE_APP_API + apiName;
    return this._http.delete(url, { headers: headersRequest, observe: 'response' })
  }

  deactivateAd(apiName:any,body:any){
    let headersRequest = new HttpHeaders({
      'No-Auth': 'false'
    })
    let url = environment.NODE_APP_API + apiName;
    return this._http.put(url,body,{headers: headersRequest, observe: 'response'});
  }
}
